import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { mq } from '../components/_shared/media';
import { blogMenuLinks } from '../components/_config/menu-links';
import { StyledSection } from '../components/_shared/styled-section';
import { StyledH1, StyledH2, StyledH3Simple } from '../components/_shared/styled-headings';
import { StyledImageContainer } from '../components/_shared/styled-image-container';
import { ButtonLink } from '../components//links/button-link';

const StyledContent = styled(StyledSection)`
  min-height: 100vh;
`;

const StyledTitle = styled(StyledH3Simple)`
  text-align: center;
`;

const StyledPostsContainer = styled.article`
  margin-top: 10rem;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 2rem;
  text-align: center;

  ${mq.gt.xs} {
    width: 50%;
  }
`;

const StyledPostContainer = styled.article`
  border: 1px solid var(--body-color);
  border-radius: var(--radius);
  background-color: var(--bg-content-color);
  padding: 1rem;
  border-radius: var(--radius);
  display: flex;
  flex-direction: column;

  & .gatsby-image-wrapper {
    max-height: 300px;
  }
`;

const CodeSource = ({ data, location }) => {
  const codeSource = data.contentfulCodeSource;
  const name = codeSource.name;
  const code_cources_url = codeSource.source_codes.file.url;
  // const additional_file_url = codeSource.additional_file  ? codeSource.additional_file.file.url : '#';
  const coverImage = codeSource.coverImage
    ? codeSource.coverImage.fluid
    : null;

  return (
    <Layout menuLinks={blogMenuLinks}>
      <StyledContent>
        <SEO title={`Codes Sources ${name}`} />
        <StyledH1>Codes Sources</StyledH1>

        <StyledPostsContainer>
          <StyledPostContainer>
            <StyledImageContainer>{coverImage && <Img fluid={coverImage} />}</StyledImageContainer>
            <StyledTitle></StyledTitle>
            <ButtonLink label="Télécharger" link={code_cources_url} download />
          </StyledPostContainer>
        </StyledPostsContainer>
        {/* <StyledH1>Fiche Récapitulative</StyledH1>
         <StyledPostsContainer>
          <StyledPostContainer>
            <ButtonLink label="Télécharger" link={additional_file_url} download />
          </StyledPostContainer>
        </StyledPostsContainer> */}
      </StyledContent>
    </Layout>
  );
};

CodeSource.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CodeSource;

export const query = graphql`
  query($slug: String!) {
    contentfulCodeSource(slug: { eq: $slug } ) {
      name
      coverImage {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid
        }
      }
      source_codes {
        file {
          url
        }
      }
    }
  }
`;
